import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  Button,
  Badge
} from 'reactstrap';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import LocalizedLink from '../components/LocalizedLink';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share';
import TriDymeIcon from '../media/TriDymeIcon.png';
import frSvg from '../components/Language/img/fr.svg';
import enSvg from '../components/Language/img/gb.svg';
import usSvg from '../components/Language/img/us.svg';
import locales from '../constants/locales';

const {
  REACT_COMPANY_NAME,
  GATSBY_WEBSITE_URL
} = process.env;


const BlogPost = ({ pageContext: { locale }, data }) => {
  const post = data.markdownRemark
  console.log('GUIGUI', post.frontmatter)
  return (
    <Layout locale={locale}>
      <SEO
        title={`TriDyme | ${post.frontmatter.title}`}
        lang={locale}
        url={locales[locale].default
          ? `/${post.fields.slug}`
          : `/${locales[locale].path}${post.fields.slug}`}
        image={locales[locale].default
          ? `/${post.frontmatter.featuredImage.publicURL}`
          : `/${locales[locale].path}${post.frontmatter.featuredImage.publicURL}`}
        description={post.frontmatter.description}
        author={post.frontmatter.author}
      />
      <Container className="blogPostsContainer">

        {/* <h1 className="title">{post.frontmatter.title}</h1>
        <h5 className="subtitle">{post.frontmatter.date}</h5>
        <h5>
          <Badge color="light" pill className="tagButton">
            {post.frontmatter.language}
          </Badge>
        </h5> */}
        {/* <hr /> */}
        <Row className="articleTitle">
          {/* <div className="articleTitle"> */}
          <img
            className="imgTitle"
            src={post.frontmatter.featuredImage.publicURL}
            // src={post.frontmatter.thumbnail}
            alt={post.frontmatter.title}
            title={post.frontmatter.title}
          />
          <h1 className="textTitle">{post.frontmatter.title}</h1>
          {/* </div> */}
        </Row>
        <br />
        <Row>
          <Col md={1} />
          <Col md={11}>
            <Breadcrumb className="breadcrumbBlog">
              <BreadcrumbItem className="breadcrumbItemBlog">
                <LocalizedLink to="/blog/">
                  Blog
                </LocalizedLink>
              </BreadcrumbItem >
              <BreadcrumbItem className="breadcrumbItemBlog">
                {post.frontmatter.title}
              </BreadcrumbItem>
            </Breadcrumb>
            <h5 className="subtitle">{post.frontmatter.date}</h5>
            {post.frontmatter.language === "fr-Fr" &&
              <img src={frSvg} alt="fr" width="20" height="20" />}
            {post.frontmatter.language === "en-Gb" &&
              <img src={enSvg} alt="en" width="20" height="20" />}
            {post.frontmatter.language === "en-Us" &&
              <img src={usSvg} alt="us" width="20" height="20" />}
            {/* <Badge color="light" pill className="tagButton">
              {post.frontmatter.language}
            </Badge> */}
            <br />
            <br />
          </Col>
        </Row>
        <Row className="main">
          <Col md={1}>
            <div className="socialBlog">
              <FacebookShareButton
                url={`https://www.tridyme.com${post.fields.slug}`}
                className="socialIcon"
                children={
                  <FacebookIcon
                    size={40}
                    round={true}
                    iconBgStyle={{ fill: '#007bff' }}
                  />
                }
              />
              <LinkedinShareButton
                url={`https://www.tridyme.com${post.fields.slug}`}
                className="socialIcon"
                children={
                  <LinkedinIcon
                    size={40}
                    round={true}
                    iconBgStyle={{ fill: '#007bff' }}
                  />
                }
              />
              <TwitterShareButton
                url={`https://www.tridyme.com${post.fields.slug}`}
                className="socialIcon"
                children={
                  <TwitterIcon
                    size={40}
                    round={true}
                    iconBgStyle={{ fill: '#007bff' }}
                  />
                }
              />
              <EmailShareButton
                url={`https://www.tridyme.com${post.fields.slug}`}
                className="socialIcon"
                children={
                  <EmailIcon
                    size={40}
                    round={true}
                    iconBgStyle={{ fill: '#007bff' }}
                  />
                }
              />
            </div>
          </Col>
          <Col md={11}>
            <div className="contentBlog">
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
          </Col>
        </Row>
        <div className="socialFooter">
          <br />
          <Row className="innerSocialFooter">
            <Col xs={4} className="shareSocial">
              {/* <p>Retrouvez nous sur:</p> */}
            </Col>
            <Col xs={1}>
              <FacebookShareButton
                url={`https://www.tridyme.com${post.fields.slug}`}
                className="socialIcon"
                children={
                  <FacebookIcon
                    size={30}
                    round={true}
                    iconBgStyle={{ fill: '#007bff' }}
                  />
                }
              />
            </Col>
            <Col xs={1}>
              <LinkedinShareButton
                url={`https://www.tridyme.com${post.fields.slug}`}
                className="socialIcon"
                children={
                  <LinkedinIcon
                    size={30}
                    round={true}
                    iconBgStyle={{ fill: '#007bff' }}
                  />
                }
              />
            </Col>
            <Col xs={1}>
              <TwitterShareButton
                url={`https://www.tridyme.com${post.fields.slug}`}
                className="socialIcon"
                children={
                  <TwitterIcon
                    size={30}
                    round={true}
                    iconBgStyle={{ fill: '#007bff' }}
                  />
                }
              />
            </Col>
            <Col xs={1}>
              <EmailShareButton
                url={`https://www.tridyme.com${post.fields.slug}`}
                className="socialIcon"
                children={
                  <EmailIcon
                    size={30}
                    round={true}
                    iconBgStyle={{ fill: '#007bff' }}
                  />
                }
              />
            </Col>
            <Col xs={4} />
          </Row>
        </div>
        <div className="footerBlog">
          <hr />
          <Row className="innerFooterBlog">
            <Col md={1} />
            <Col md={11}>
              <h5 className="subtitle">{post.frontmatter.author}</h5>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  )
}


BlogPost.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default BlogPost;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "DD-MM-YYYY")
        author
        language
        description
        featuredImage {
          publicURL
        }
      }
      fields {
        slug
      }
    }
  }
`
